<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button" >
                <div class="h-b"> <el-button type="primary" size="small" @click="addMemberInfo" v-right-code="'Memberinfo:Createshipprovider'">新增</el-button></div>
            </div>
            <p slot="elList">
                <el-table ref="memberInfoListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" @current-change="handleCurrentChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='MemberCode'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" :min-width="150" v-if="!config.isDetailDisplay" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="setMemeberStatus(scope.row)" v-if="scope.row.MemberStatus==1" v-right-code="'Memberinfo:Setshipmemberstatus'" size="small" type="text">停用</el-button>
                            <el-button @click="setMemeberStatus(scope.row)" v-else v-right-code="'Memberinfo:Setshipmemberstatus'" size="small" type="text">启用</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadMemberInfoPageList", () => this.reloadPageList());
            this.Event.$on("onAddMemberInfo", () => this.addMemberInfo());
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                isPassValidate: true,
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                MemberId: '',
                UserId: '',
                UserName: ''
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            setMemeberStatus(row) {//停用承运商
                var _this = this;
                var tips = row.MemberStatus == 1 ? '停用' : '启用';
                this.$confirm(`确定${tips}承运商 ${row.MemberName} 吗, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ajax.send("omsapi/memberinfo/setshipmemberstatus", "post", row, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox(data.OperationDesc, "success");
                            _this.initialize();
                        } else {
                            _this.Utils.messageBox(data.OperationDesc, "error");
                        }
                    });
                }).catch(() => {
                });
                _this.initialize();
            },
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleCurrentChange: function (val) {
                this.$emit("onSelectedRow", val);
                this.$emit("onSelectedRow2", val);
                this.$emit("onSelectedRow3", val);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/memberinfo/searchshipprovider", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick(row) {
                this.$ajax.send("omsapi/memberinfo/getshipproviderinfobyid", "get", { id: row.MemberId }, (data) => {
                    this.Event.$emit("getOrderItemList", row.MemberId);
                    this.Event.$emit("clearEditMemberInfoForm");
					
					if(!data.Result.CertList){
						
						data.Result.CertList=null;
					}
					
                    this.onChangeEditDataSource(data.Result);
                });
            },
            addMemberInfo() {
                this.$ajax.send("omsapi/memberinfo/getemptyshipprovider", "get", {}, (data) => {
                    this.Event.$emit("getOrderItemList", '');
                    data.Result.MemberProductList = [];
					if(!data.Result.CertList){
						
						data.Result.CertList=null;
					}
                    this.onChangeEditDataSource(data.Result);
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            }
        }
    }
</script>
<style>
</style>